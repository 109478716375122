// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import IntroAndPicture from 'components/Contentful/IntroAndPicture'
import YouTubeLink from 'components/Contentful/YouTubeLink'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Heizkosten sparen - so gehts"
        meta={[
          { name: 'description', content: 'Heizkosten sparen mit diesen 10 Tipps ➤ Richtige Einstellungen ✔️ Thermostat bedienen ✔️ Regelmässiges Entlüften ✔️ Jetzt hier informieren!' },
          { name: 'keywords', content: 'Heizkosten sparen' },
        ]}
      >
        
      </Helmet>
      
      <div
          className='bg__flex--white'
          key='7yMqYHeui2mpM68vTstZDA-4t6DRJTAC9Tze8tNNimI6B'
          id='heizkosten-sparen'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "4t6DRJTAC9Tze8tNNimI6B",
    "updatedAt": "2024-03-04T15:00:18.731Z",
    "title": "Heizkosten sparen",
    "anchorName": "heizkosten-sparen",
    "text": "<ul>\n<li><a href=\"https://www.heizungsmacher.ch/wissen/heizkosten-sparen/#heizung-richtig-einstellen\">Heizung richtig einstellen</a></li>\n<li><a href=\"https://www.heizungsmacher.ch/wissen/heizkosten-sparen/#daemmung-isolation-der-gebaeudehuelle\">Dämmung/Isolation der Heizungsrohre</a></li>\n<li><a href=\"https://www.heizungsmacher.ch/wissen/heizkosten-sparen/#isolation-der-heizungsrohre\">Isolation der Heizungsrohre</a></li>\n<li><a href=\"https://www.heizungsmacher.ch/wissen/heizkosten-sparen/#thermostat-richtig-einstellen\">Thermostat richtig einstellen</a></li>\n<li><a href=\"https://www.heizungsmacher.ch/wissen/heizkosten-sparen/#heizkoerper-nicht-bedecken-zustellen\">Heizkörper nicht bedecken/zustellen</a></li>\n<li><a href=\"https://www.heizungsmacher.ch/wissen/heizkosten-sparen/#heizung-gelegentlich-entlueften\">Heizung gelegentlich entlüften</a></li>\n<li><a href=\"https://www.heizungsmacher.ch/wissen/heizkosten-sparen/#reduzierte-einstellung-bei-abwesenheit\">Reduzierte Einstellung bei Abwesenheit</a></li>\n<li><a href=\"https://www.heizungsmacher.ch/wissen/heizkosten-sparen/#fenster-nicht-laufend-auf-kipp-lieber-stosslueften\">Fenster nicht laufend auf Kipp, lieber Stosslüften</a></li>\n<li><a href=\"https://www.heizungsmacher.ch/wissen/heizkosten-sparen/#abdichten-von-fenster-und-tueren\">Abdichten von Fenster und Türen</a></li>\n<li><a href=\"https://www.heizungsmacher.ch/wissen/heizkosten-sparen/#initiale-vs-langfristige-investition-bei-heizsystemen-beachten\">Initiale vs. langfristige Investition bei Heizsystemen beachten</a></li>\n<li><a href=\"https://www.heizungsmacher.ch/wissen/heizkosten-sparen/#wuenschen-sie-eine-beratung\">Wünschen Sie eine Beratung?</a></li>\n</ul>\n",
    "image": {
        "description": "3D Illustration Heizkosten senken Energieeffizeinz",
        "contentType": "image/png",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/56nPUBg31tbueKsuHKXOlC/4251051ca18e7a6eb3c892bedb3146d9/2110---Heizkosten-sparen-Einleitung_Text.png"
    }
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='7yMqYHeui2mpM68vTstZDA-277HhzKj2HNm8Kco5upxSr'
          id='10-tipps-um-nachhaltig-heizkosten-zu-sparen'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "277HhzKj2HNm8Kco5upxSr",
    "updatedAt": "2021-10-25T17:33:12.112Z",
    "title": "10 Tipps um nachhaltig Heizkosten zu sparen",
    "anchorName": "10-tipps-um-nachhaltig-heizkosten-zu-sparen",
    "text": "<p>Die Energie für die Wärmeerzeugung macht den grössten Anteil der gesamten Energie in einem Haushalt aus. Angesichts dessen mag es kaum verwundern, dass sich hier relativ hohe Energiesparpotenziale realisieren lassen. <strong>Im Folgenden haben wir einige Energiespartipps zusammengestellt, mit deren Hilfe sich die Heizkosten nachhaltig senken lassen.</strong> Einige davon lassen sich sofort umsetzen, andere erfordern Investitionen, die sich aber je nach Ausgangssituation schnell lohnen können.</p>\n"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='7yMqYHeui2mpM68vTstZDA-5A55IL5mmUMlrIy2HMkfXA'
          id='heizung-richtig-einstellen'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "5A55IL5mmUMlrIy2HMkfXA",
    "updatedAt": "2022-10-31T09:44:56.407Z",
    "title": "1. Heizung richtig einstellen",
    "anchorName": "heizung-richtig-einstellen",
    "text": "<p>Eine richtig eingestellte Heizung ist effizient und bringt den richtigen Komfort. Gerade <a href=\"https://www.heizungsmacher.ch/waermepumpen/\">Wärmepumpen</a> sind heute smart und verfügen über diverse Parameter, welche korrekt eingestellt werden müssen. Dank eines <a href=\"https://www.heizungsmacher.ch/monitoring/\">aktiven Monitorings</a>, können Sie nicht nur Heizkosten sparen, sondern die Wärmepumpe ganz generell überwachen und Serviceeinsätze frühzeitig koordinieren lassen. <strong>Die Lebensdauer einer Wärmepumpe hängt primär von zwei Faktoren ab: Anzahl Einschaltungen und Betriebsstunden. Stimmt dieses Verhältnis, steht einem effizienten Betrieb und damit verbundenen gesparten Heizkosten nichts im Weg.</strong></p>\n"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='7yMqYHeui2mpM68vTstZDA-zseMbZSxdEPqq59pf9PX9'
          id='daemmung-isolation-der-gebaeudehuelle'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "zseMbZSxdEPqq59pf9PX9",
    "updatedAt": "2022-10-31T09:46:53.713Z",
    "title": "2. Dämmung/Isolation der Gebäudehülle",
    "anchorName": "daemmung-isolation-der-gebaeudehuelle",
    "text": "<p>Sie können Heizkosten sparen, wenn die Gebäudehülle Ihres Heimes gut isoliert ist und dadurch keine Wärme entweichen kann. Eine <a href=\"https://erneuerbarheizen.ch/impulsberatung/\">Impulsberatung</a> kann aufzeigen, an welchen Stellen Verbesserungspotenzial besteht.</p>\n"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='7yMqYHeui2mpM68vTstZDA-7MwI9VYaEDiA25GacwRFKF'
          id='isolation-der-heizungsrohre'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "7MwI9VYaEDiA25GacwRFKF",
    "updatedAt": "2021-10-25T17:32:57.172Z",
    "title": "3. Isolation der Heizungsrohre",
    "anchorName": "isolation-der-heizungsrohre",
    "text": "<p>Auch <strong>die Heizungsrohre sollten gut isoliert werden</strong>, beispielsweise mit einer Ummantelung aus Schaumstoff. Vor allem dort sollte man Leitungen dämmen, wo sie durch unbeheizte Räume wie zum Beispiel Keller verlaufen. Die Heizkostenersparnis liegt bei rund acht Prozent.</p>\n"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='7yMqYHeui2mpM68vTstZDA-3Lcu6Nov1zofypXQAz8c7B'
          id='thermostat-richtig-einstellen'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "3Lcu6Nov1zofypXQAz8c7B",
    "updatedAt": "2021-10-25T17:32:53.420Z",
    "title": "4. Thermostat richtig einstellen",
    "anchorName": "thermostat-richtig-einstellen",
    "text": "<p><strong>Falsch eingestellte Thermostatventile bedeuten unnötig verbrauchte Energie.</strong> Wenn die Raumtemperatur höher ist als nötig, führt dies pro Grad zu rund sechs Prozent höherem Verbrauch. So reichen zum Beispiel im Schlafzimmer 18 Grad oder in anderen wenig genutzten Räumen sogar 16 Grad um ein angenehmes Raumklima zu erreichen. Wichtig ist, dass kühlere Räume gut belüftet werden und die Türen zu wärmeren Räumen geschlossen bleiben. Sonst kann sich an kälteren Stellen Schimmel bilden.</p>\n<p>Wenn es mal zu kalt ist, ist zu beachten, dass der <strong>Thermostat zum Aufheizen nicht auf das Maximum gestellt</strong> wird - es geht nicht schneller. Die Leitung öffnet automatisch so lange, bis die Wunschtemperatur im Raum erreicht ist.</p>\n"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='7yMqYHeui2mpM68vTstZDA-2HVWxApAQpxsaA1FXIHYsJ'
          id='heizkoerper-nicht-bedecken-zustellen'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "2HVWxApAQpxsaA1FXIHYsJ",
    "updatedAt": "2021-10-25T17:32:49.493Z",
    "title": "5. Heizkörper nicht bedecken/zustellen",
    "anchorName": "heizkoerper-nicht-bedecken-zustellen",
    "text": "<p>Damit ein Heizkörper richtig arbeiten kann, benötigt er genügend Luft. Die ist nötig, damit das Prinzip der Konvektion überhaupt funktionieren kann. Normale Heizkörper sind nämlich nicht darauf ausgelegt, Wärme abzustrahlen. Vielmehr sollen sie kalte Luft ansaugen, erwärmen und dann nach oben hin abgeben. Dort verwirbelt sich die warme Luft mit der kühleren Raumluft, was zu einer gleichmässigeren Erwärmung des Raumes führt. Nicht umsonst sind die meisten Heizkörper direkt unter Fenstern platziert, denn dort ist die Luft am kühlsten und es kommt zu einer besseren Verwirbelung. <strong>Heizkörper sollten also nicht mit Möbeln zugestellt werden, weil dann schlicht zu wenig Luft zur Verfügung steht, die erwärmt werden kann. Ein Abstand von 20 Zentimetern ist hier das Minimum, um die Effektivität nicht zu beeinträchtigen.</strong> Gleiches gilt für Vorhänge. Diese sollten bei eingeschalteter Heizung beiseite geschoben werden, um Energie einzusparen und die Heizkosten zu senken.</p>\n"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='7yMqYHeui2mpM68vTstZDA-2GT9h1qMRjYkoJASH0PCmy'
          id='heizung-gelegentlich-entlueften'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "2GT9h1qMRjYkoJASH0PCmy",
    "updatedAt": "2021-10-25T17:32:45.254Z",
    "title": "6. Heizung gelegentlich entlüften",
    "anchorName": "heizung-gelegentlich-entlueften",
    "text": "<p>Auch Luft kann zusätzliche Heizkosten generieren. <strong>Stellen Sie fest, dass die Heizkörper nicht richtig oder unterschiedlich warm werden und Gluckergeräusche zu hören sind, ist eventuell Luft im System.</strong> Dies führt zu einem höheren Verbrauch. Mit einem Entlüfterschlüssel können Sie ganz einfach selbst die Luft aus dem heissen Heizkörper rauslassen. Nach dieser Entlüftung ist der Heizkörper wieder komplett mit Wasser gefüllt. Überprüfen Sie sowohl vor als auch nach der Entlüftung den Druck im System. Allenfalls muss Wasser nachgefüllt werden.</p>\n"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='7yMqYHeui2mpM68vTstZDA-nKMzxHv6JndhwKOIg5j4V'
          id='reduzierte-einstellung-bei-abwesenheit'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "nKMzxHv6JndhwKOIg5j4V",
    "updatedAt": "2021-10-25T17:32:40.625Z",
    "title": "7. Reduzierte Einstellung bei Abwesenheit",
    "anchorName": "reduzierte-einstellung-bei-abwesenheit",
    "text": "<p><strong>Ein intelligentes Heizsystem erlaubt es, individuelle Einstellungen vorzunehmen.</strong> Je nach Tageszeit kann die Temperatur hinauf oder hinunter geregelt werden, damit das Thermostat automatisch öffnen oder abriegeln kann. Über eine Applikation kann so auch eine Ferienabwesenheit eingetragen werden. In dieser Zeit empfiehlt es sich, die Heizung auf sehr niedrigem Niveau von rund 18 °C weiterlaufen zu lassen. Komplett abstellen ist nicht empfohlen, da der Brennstoffverbrauch für das Aufwärmen bei der Rückkehr überproportional hoch ist.</p>\n<p>Wer kein smartes System hat, nimmt die Einstellungen manuell vor. Auch so lassen sich Heizkosten sparen.</p>\n"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='7yMqYHeui2mpM68vTstZDA-1VpL8uT7amivoxnsJfezLA'
          id='fenster-nicht-laufend-auf-kipp-lieber-stosslueften'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "1VpL8uT7amivoxnsJfezLA",
    "updatedAt": "2021-10-25T17:32:35.765Z",
    "title": "8. Fenster nicht laufend auf Kipp, lieber Stosslüften",
    "anchorName": "fenster-nicht-laufend-auf-kipp-lieber-stosslueften",
    "text": "<p>Eine sehr einfache Methode, die Heizkosten nicht zu sehr ansteigen zu lassen, liegt darin, die <strong>Wohnung richtig zu lüften</strong>. Die Kippfunktion an Fenstern und Balkontüren mag praktisch erscheinen, aber gerade in der kälteren Jahreszeit kann sie auch sehr teuer werden. Über das gekippte Fenster entweicht kontinuierlich Wärme aus den Wohnräumen und die Wohnung selbst kühlt immer mehr aus - also auch Möbel und andere Einrichtungsgegenstände, die ebenfalls Wärme speichern. <strong>Stattdessen sollten mehrmals am Tag alle Fenster geöffnet und so ein kräftiger Durchzug ausgelöst werden.</strong> Auf diesem Wege wird die Luft - je nach Grösse der Wohnung und Anzahl der Fenster - in wenigen Minuten komplett ausgetauscht, ohne dass zu viel Wärme verloren geht.</p>\n"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='7yMqYHeui2mpM68vTstZDA-5pGxkrrYlSqnqYYrok2ko0'
          id='abdichten-von-fenster-und-tueren'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "5pGxkrrYlSqnqYYrok2ko0",
    "updatedAt": "2021-10-25T17:32:31.298Z",
    "title": "9. Abdichten von Fenster und Türen",
    "anchorName": "abdichten-von-fenster-und-tueren",
    "text": "<p>Undichte Fenster oder Aussentüren sorgen für unangenehme Zugluft und Wärmeverluste in der Wohnung. <strong>Um die Dichtheit zu prüfen, klemmen Sie ein Blatt Papier zwischen Fensterrahmen und Fensterflügel ein.</strong> Falls sich das Papier bei geschlossenem Rahmen nicht herausziehen lässt, ist das Fenster an dieser Stelle dicht genug. Wiederholen Sie den Papiertest an mehreren Stellen.</p>\n<p>Bei Fenstern reicht es meistens schon, die Dichtprofile zu erneuern oder die Fensterflügel zu justieren. Bei Wohnungs- oder Haustüren mit offenem Türschlitz kann oftmals nachträglich ein Dichtprofil, ein sogenannter Kältefeind, eingebaut werden. Haben Sie einen Windfang, sollten Sie ihn bei kalter Witterung stets geschlossen halten.</p>\n"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='7yMqYHeui2mpM68vTstZDA-3hF2XqvB7YML5JULWt4qPB'
          id='initiale-vs-langfristige-investition-bei-heizsystemen-beachten'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "3hF2XqvB7YML5JULWt4qPB",
    "updatedAt": "2022-10-31T10:26:58.214Z",
    "title": "10. Initiale vs. langfristige Investition bei Heizsystemen beachten",
    "anchorName": "initiale-vs-langfristige-investition-bei-heizsystemen-beachten",
    "text": "<p>Letztlich sparen Sie auf Dauer am besten Heizkosten, wenn Sie Ihre alte Heizung durch eine moderne Wärmepumpe austauschen lassen. Insbesondere <a href=\"https://www.heizungsmacher.ch/wissen/oelheizung-ersetzen-informationen-foerderungen-regelungen/\">alte Ölheizungen</a> und <a href=\"https://www.heizungsmacher.ch/wissen/gasheizung-ersetzen-kosten-foerderungen-und-regelungen-in-der-schweiz/\">Gasheizungen</a> sind im Vergleich zu modernen Anlagen aufgrund des hohen Brennstoffverbrauchs teuer. Sowohl das Energiesparen, als auch das Sparen der Heizkosten wird erschwert. Auch wenn initial eine <a href=\"https://www.heizungsmacher.ch/wissen/wie-hoch-sind-die-kosten-einer-waermepumpe/\">hohe Investition</a> anfällt - eines ist sicher: das Geld holt man dank der hohen Einsparungen im Betrieb und Unterhalt schnell wieder rein und nebenbei heizt man viel umweltschonender. Dabei sollte immer auch ein Wechsel des Brennstoffs in Erwägung gezogen werden. Fossile Brennstoffe wie Öl und Gas, deren Vorkommen begrenzt sind und die unserer Umwelt schaden, werden tendenziell teurer, da Staaten diese verbieten wollen.</p>\n"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='7yMqYHeui2mpM68vTstZDA-7sZJ9m3sILRepCWlRYy0MX'
          id=''
        >
          <YouTubeLink
            {...{
    "type": "youTubeLink",
    "id": "7sZJ9m3sILRepCWlRYy0MX",
    "updatedAt": "2021-10-25T18:08:01.168Z",
    "title": "Wie kann ich beim Heizen Energie und Kosten sparen? Erklärvideo",
    "link": "https://www.youtube.com/watch?v=ubJqg8PdD_E&t=118s",
    "videoId": "ubJqg8PdD_E",
    "start": "118s",
    "description": ""
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='7yMqYHeui2mpM68vTstZDA-5jl6yjDwGax05p7DpzlEBw'
          id='wuenschen-sie-eine-beratung'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "5jl6yjDwGax05p7DpzlEBw",
    "updatedAt": "2023-08-30T09:41:16.098Z",
    "title": "Wünschen Sie eine Beratung?",
    "anchorName": "wuenschen-sie-eine-beratung",
    "text": "<p>Sie interessieren sich für eine Wärmepumpe und sind noch nicht sicher, ob dies die richtige Wahl ist? Besuchen Sie unseren kostenlosen Heizungs-Check oder lassen Sie sich von unseren <a href=\"https://www.heizungsmacher.ch/ueber-uns/\" title=\"ueber-uns\">Heizexperten</a> beraten.</p>\n",
    "link": {
        "type": "link",
        "id": "7p7eaG5GRI8A1fElOkNxIb",
        "updatedAt": "2022-06-28T08:23:33.929Z",
        "label": "Online Heizungs-Check",
        "url": "https://www.heizungsmacher.ch/konfigurator"
    }
}} location={props.location}
          />
        </div>
    </Layout>
  )
}

export default Page
